import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../classes/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(new User(null));
  user$: Observable<User> = this.user.asObservable();

  constructor() { }

  setUser(u: User): void {
    this.user.next(u);
  }

  getUser(): User {
    return this.user.getValue();
  }

  get getUserType(): string {
    return this.user?.getValue()?.adm_type;
  }

  get getUserFirstName(): string {
    return this.user?.getValue()?.adm_name;
  }

  get getFullUserName(): string {
    return this.user?.getValue()?.adm_brandname;
  }

  get getUserEmail(): string {
    return this.user?.getValue()?.adm_username;
  }

  get getUserId(): number {
    return this.user?.getValue()?.adm_id;
  }

  get getLoginDate(): string {
    return this.user?.getValue()?.adm_udate;
  }

  get getUserImage(): string {
    return this.user?.getValue()?.adm_image;
  }

}
