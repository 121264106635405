import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RestResponse} from "../classes/rest-response";
import {ApiHeaders} from "../classes/api-headers";

@Injectable()
export class MainProvider {
  baseUrl: string = environment.BASE_URL;
  baseUrl2: string = environment.BASE_URL2;

  constructor(private http: HttpClient) {
  }

  logoutAdmin(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AdminUpdate`, formData);
  }

  AdminControl(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AdminControl`, formData);
  }

  getEmployeeList(searchKey: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/EmployeeAllList?SearchTerm=${searchKey}`, ApiHeaders.getOptions());
  }

  getHODList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/HODEmployeeAllList`, ApiHeaders.getOptions());
  }

  getReportingToList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/ReportingEmployeeAllList`, ApiHeaders.getOptions());
  }

  addEmployeeInfo(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/AddEmployeeInfo`, saveObj, ApiHeaders.getOptions());
  }

  addEmployeePersonalInfo(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/AddEmployeePersonalDetail`, saveObj, ApiHeaders.getOptions());
  }

  addEmployeeBankInfo(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/AddEmployeeBankDetails`, saveObj, ApiHeaders.getOptions());
  }

  addEmployeeQualification(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/AddEmpQualification`, saveObj, ApiHeaders.getOptions());
  }

  addEmployeeWorkExp(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/AddEmpWorkExperience`, saveObj, ApiHeaders.getOptions());
  }

  addEmployeeAttechment(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/Employee/EmployeeDocumentUpload`, formData);
  }

  getEmployeeQualification(employeeId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/GetEmpQualificationListByUserId?UserId=${employeeId}`, ApiHeaders.getOptions());
  }

  getEmployeeWorkExp(employeeId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/GetEmpWorkExperienceListByUserId?UserId=${employeeId}`, ApiHeaders.getOptions());
  }

  getEmployeePersonalInfoAndPayment(employeeId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/Employee/EmployeeDetail?Id=${employeeId}`, ApiHeaders.getOptions());
  }

  AddTask(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AddTask`, formData);
  }

  TaskList(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/TaskList`, formData);
  }

  TaskDelete(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/TaskDelete`, formData);
  }

  TaskDetailsList(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/TaskDetailsList`, formData);
  }

  OrderList(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/OrderList`, formData);
  }

  OrderUpdate(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/OrderUpdate`, formData);
  }

  ItemCancel(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/ItemCancel`, formData);
  }

  OrderListReport(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/OrderListReport`, formData);
  }

  GetAttendanceData(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/GetAttendanceData`, formData);
  }
  
  UpdateAttendance(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/UpdateAttendance`, formData);
  }

  NotificationList(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/NotificationList`, formData);
  }

  AddEditNotification(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AddEditNotification`, formData);
  }

  NotificationDelete(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/NotificationDelete`, formData);
  }

  PushNotificationUser(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl2 + `/PushNotificationUser`, formData);
  }

  AdminDashboard(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AdminDashboard`, formData);
  }

  AdminDashboard2(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/AdminDashboard2`, formData);
  }
  
  ApplicationControl(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/ApplicationControl`, formData);
  }
  
  ApplicationControlUpdate(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/ApplicationControlUpdate`, formData);
  }

  FindLastPriceVendorWise(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/FindLastPriceVendorWise`, formData);
  } 
  
  UpdateLastPrice(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/UpdateLastPrice`, formData);
  }

  TaskImageUnlink(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/TaskImageUnlink`, formData);
  }

 


}
